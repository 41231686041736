import {isEmpty, isNotEmpty} from "../functions/common";


// TYPES
export type AccessRightType = string | number | null;

export type AccessRightValueType = string | number | null;

export type AccessRightNamesListType = string[];
export type AccessRightValuesListType = AccessRightValueType[];

export type AccessRightsType = {
    [key: string]: AccessRightType;
};

export type UseAccessRightsType = {
    values: AccessRightsType,

    // функции получения значений
    get        : (name: string) => AccessRightType,
    getList    : (namesList: AccessRightNamesListType) => AccessRightsType

    getProp    : (name: string, defaultValue?: any) => AccessRightValueType,
    getPropList: (name: string) => AccessRightValuesListType,
    getPropJSON: (name: string) => Object,

    // проверки на значение
    is       : (name: string, value: string ) => boolean,
    isNot    : (name: string, value: string ) => boolean,

    isOr     : (name: string, value: string[] ) => boolean,
};


// MAIN
export const useAccessRights: (values: any, defaultAccessRightValue: AccessRightType) => UseAccessRightsType = (values, defaultAccessRightValue) => {

    const getAccessRightData: (name: string, dataName: string, defaultValue: AccessRightType) => AccessRightType = (name, dataName, defaultValue) =>
        (values && values[name] && isNotEmpty(values[name][dataName])) ? values[name][dataName] : defaultValue;

    const getAccessRightValueByName    : (name: string                    ) => AccessRightType      = (name                     ) => getAccessRightData(name, 'value', defaultAccessRightValue);
    const getAccessRightPropByValueName: (name: string, defaultValue?: any) => AccessRightValueType = (name, defaultValue = null) => getAccessRightData(name, 'prop' , defaultValue           );


    const getAccessRightValuesListByNamesList: (namesList: AccessRightNamesListType) => AccessRightsType = (namesList) => {
        let result: AccessRightsType = {};
        namesList.map((name) => { result = {...result, [name]: getAccessRightValueByName(name)}; });
        return result;
    }

    const getAccessRightPropByValueNameToList: (name: string) => AccessRightValuesListType = (name) => {
        const prop = getAccessRightPropByValueName(name);

        if (isEmpty(prop)) return [];

        return String(prop).split(',').map( (item) => item.trim() ).filter( (item) => isNotEmpty(item) );
    }

    const getAccessRightPropByValueNameToJSON: (name: string) => Object = (name) => {
        const prop = getAccessRightPropByValueName(name);

        return (isEmpty(prop)) ? {} : JSON.parse(String(prop));
    }


    const is    = (name: string, value: string ) => (getAccessRightValueByName(name) === value);
    const isNot = (name: string, value: string ) => (getAccessRightValueByName(name) !== value);

    const isOr  = (name: string, values: string[] ) => {
        let result = false;
        values.map( (value) => { result = result || is(name, value); } )
        return result;
    };

    return {
        values: values,

        // функции получения значений
        get        : getAccessRightValueByName,

        getProp    : getAccessRightPropByValueName,
        getPropList: getAccessRightPropByValueNameToList,
        getPropJSON: getAccessRightPropByValueNameToJSON,

        getList    : getAccessRightValuesListByNamesList,

        // проверки на значение
        is         : is,
        isNot      : isNot,

        isOr       : isOr,
    };

};
