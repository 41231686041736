import React from "react";

import { Link } from "react-router-dom";

import styles from "./VKVideoLink.module.css";

import vkvideo from "../../../assets/svg/vkvideo.svg";

export const VKVideoLink = () => {
  return (
    <a
      href={
        "https://vkvideo.ru/playlist/-208533011_11"
      }
      target="_blank"
      rel="noopener"
      className={styles.container}
    >
      <img src={vkvideo} className={styles.icon} />
      <div className={styles.text}>
        Видеоинструкция по регистрации и заполнению заявки
      </div>
    </a>
  );
};
